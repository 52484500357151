<template>
  <div class="default-state">
    <div class="direction-list">
      <a v-for="direction in directions" :key="direction.id" class="direction-list__item"
        :style="`background-color: ${colors[direction.code.toLowerCase()] || '#000'}`"
        @click="chooseDirection(direction.code)">
        <img class="logo-small" src="@/assets/images/svg/logo-small.svg" alt="">
        <p class="direction-list__title">{{ direction.name }}</p>
      </a>
    </div>
    <!--    Вернется после MVP в рамках реализации рекламы -->
    <!--    <div class="banner">-->
    <!--      <img src="@/assets/images/banner.jpg" alt="" class="banner__pic">-->
    <!--      <div class="banner__body">-->
    <!--        <p class="banner__content">-->
    <!--          Хотите систему отопления, водоснабжения на вашем объекте? Нет времени выбирать?-->
    <!--        </p>-->
    <!--        <div class="banner-box">-->
    <!--          <button class="btn banner__btn btn-green" type="button">-->
    <!--            Заказать обратный звонок-->
    <!--            <span class="banner__icon">-->
    <!--            <img src="@/assets/images/svg/arrow-right.svg" alt="">-->
    <!--          </span>-->
    <!--          </button>-->
    <!--          <img src="@/assets/images/svg/logo.svg" alt="" class="banner__logo">-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="company">
      <img class="company-logo" src="@/assets/images/svg/company-logo.svg" alt="">
      <p class="company-content">Проектирование и дизайн</p>
    </div>
  </div>
</template>

<script>
import colors from '@/helpers/DirectionColors';

export default {
  name: 'DefaultState',
  data() {
    return {
      colors
    }
  },
  methods: {
    chooseDirection(directionCode) {
      this.$store.dispatch('setActiveDirections', [directionCode]);
      this.$store.dispatch('setIsLoading', true);
      let filtered = [];
      if (this.activeLayer === 'objects' && this.objects) {
        filtered = this.objects.filter(obj => obj.directions.includes(directionCode.toLowerCase()));
      } else if (this.activeLayer === 'partners' && this.partners) {
        filtered = this.partners.filter(partner => partner.directions.includes(directionCode.toLowerCase()));
      }
      if (filtered.length) {
        this.$store.dispatch('applyFilters', filtered);
      }
      this.$store.dispatch('setIsLoading', false);
      this.$router.push(`/${this.activeLayer}/filters`);
    }
  },
  computed: {
    directions() {
      return this.$store.getters['getDirections'];
    },
    activeLayer() {
      return this.$store.getters['getActiveLayer'];
    },
    objects() {
      return this.$store.getters['getObjects'];
    },
    partners() {
      return this.$store.getters['getPartners'];
    }
  }
}
</script>

<style scoped>
.default-state {
  padding: 0 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.direction-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.direction-list__item {
  display: flex;
  align-items: center;
  width: 48.5%;
  padding: 8px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.direction-list__item--green {
  background: #58BCAF;
}

.direction-list__item--pink {
  background: #DC143C;
}

.direction-list__item--black {
  background: #000000;
}

.direction-list__item--gray {
  background: #4E4E4E;
}

.direction-list__title {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  line-height: 14px;
  margin-left: 1px;
}

.banner {
  margin-bottom: auto;
}

.banner__pic {
  height: 137px;
  object-fit: cover;
}

.banner__content {
  color: #000000;
  font-weight: 900;
  font-size: 21px;
  line-height: 25px;
  margin-bottom: 24px;
}

.banner-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner__logo {
  width: 98px;
  height: 31px;
  object-fit: cover;
}

.banner__btn {
  position: relative;
  width: calc(100% - 110px);
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  font-weight: bold;
  font-size: 16px;
}

.banner__icon {
  width: 14px;
  height: 8px;
}

.banner__body {
  padding: 24px 20px 20px 20px;
  border: 1px solid #BFBFBF;
}

.logo-small {
  width: 41px;
  height: 40px;
  margin-right: 5px;
}

.company {
  //margin-top: 20px;
  margin-top: auto;
}

.company-logo {
  width: 130px;
  height: 40px;
  object-fit: cover;
  margin-bottom: 5px;
}

.company-content {
  font-size: 9px;
  line-height: 24px;
  color: #828484;
}

@media (max-width: 1023px) {
  .default-state {
    display: none;
  }
}
</style>
